import { ButtonProps } from "@mui/material";
import { Colors } from "styles";

const WidthSize = {
  small: 100,
  medium: 176,
  large: 216,
};

const HeightSize = {
  small: 40,
  medium: 40,
  large: 48,
};

const Background: CommonJSON<string> = {
  primary: Colors.orange,
  secondary: Colors.white,
};

const Color: CommonJSON<string> = {
  primary: Colors.white,
  secondary: Colors.orange,
};

interface ButtonStylesProps {
  isMobile: boolean;
  color: ButtonProps["color"];
  size: keyof typeof WidthSize;
  disabled: boolean;
}

const getStyles = ({ color, size, disabled }: ButtonStylesProps): Styles => ({
  //Containers
  button: {
    gap: 24,
    minWidth: WidthSize[size],
    background: disabled ? Colors.extraExtraLightgray : color ? Background[color] : undefined,
    color: disabled ? Colors.white : color ? Color[color] : undefined,
    height: HeightSize[size],
    borderColor: Colors.primary,
    textTransform: "none",
  },
});

export default getStyles;
