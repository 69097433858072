import React from "react";

import getStyles from "./style";

interface ImageProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  Icon?: Icon;
  url?: string;
}

const Image: React.FC<ImageProps> = ({ Icon, url, ...props }) => {
  const BaseStyles = getStyles(false);

  return (
    <>
      {url && <img src={url} alt="" style={BaseStyles.icon} {...props} />}
      {!url && Icon && <Icon style={BaseStyles.icon} />}
    </>
  );
};

export default Image;
