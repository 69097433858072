export enum HTTPMethod {
  POST = "POST",
  GET = "GET",
  DELETE = "DELETE",
  PUT = "PUT",
  PATCH = "PATCH",
}

interface HttpRequest {
  headers?: CommonJSON;
  body?: string;
  method: HTTPMethod;
}

const buildRequest = (verb: HTTPMethod, data?: CommonJSON, headers?: CommonJSON) => {
  const request: HttpRequest = {
    method: verb,
  };

  request.headers = headers ?? {};
  if (data) {
    request.headers["Content-Type"] = "application/json";
  }

  if (verb === HTTPMethod.POST) {
    request.body = JSON.stringify(data);
  } else if (verb === HTTPMethod.PUT) {
    request.body = JSON.stringify(data);
  } else if (verb === HTTPMethod.PATCH) {
    request.body = JSON.stringify(data);
  }

  return request;
};

const createParams = (data: CommonJSON) => {
  const params = Object.entries(data).reduce((acc, [key, value], index) => {
    const result = `${key}=${value}`;
    if (index === 0) {
      return `?${result}`;
    }

    return `${acc}&${result}`;
  }, "");

  return params;
};

export interface SendRequestProps {
  url: string;
  params?: CommonJSON;
  data?: CommonJSON;
  method?: HTTPMethod;
  headers?: CommonJSON;
}

export const sendRequest = async <TResponse extends CommonJSON = CommonJSON>({
  url,
  params,
  data,
  method = HTTPMethod.GET,
  headers,
}: SendRequestProps): Promise<CommonJSON | undefined> => {
  const request = buildRequest(method, data, headers);

  const paramsParsed = createParams(params || {});
  const reqUrl = paramsParsed ? `${url}${paramsParsed}` : url;

  try {
    const response = await fetch(reqUrl, request);

    return (response.json() as unknown as TResponse).catch(() => ({}));
  } catch (e) {
    console.error("error", e);
    throw e;
  }
};
