// Material UI
import { Box, TextField } from "@mui/material";
// Components
import { CardWithImage as EmptyStateCard } from "components/business";
import { Button, CustomText, Flex } from "components/shared";
// Images
import { EmptyStateWallet } from "Images";
import React, { useEffect, useState } from "react";
// Crypto
import { useAccount, useSigner } from "wagmi";

// Styles
import BaseStyles from "./style";

// eslint-disable-next-line max-len
const line1 =
  "Please connect your blockchain wallet so we can check if you are eligible to access our online courses.";

const Signature: React.FC = () => {
  const { data: rainbowData } = useAccount();
  const { data: signer } = useSigner();
  const [signature, setSignature] = useState("");
  const [nowDate, setNowDate] = useState("");
  const address = rainbowData?.address;

  useEffect(() => setNowDate(`${new Date().getTime()}`), []);

  if (!address) {
    return (
      <Box paddingTop="32px">
        <EmptyStateCard
          Icon={EmptyStateWallet}
          title="Welcome to the Token Gated Dapp!"
          FooterComponent={() => <CustomText>{line1}</CustomText>}
          callToActionProps={{ connect: true }}
        />
      </Box>
    );
  }

  const generateSignature = async () => {
    const mssg = await signer?.signMessage(nowDate);
    setSignature(mssg!);
  };

  return (
    <Flex styles={BaseStyles.root}>
      <Flex>
        <TextField helperText="Message to be signed" value={nowDate} disabled />
        <Button text="Sign Message" onClick={generateSignature} size="small" variant="contained" />
        {signature}
      </Flex>
    </Flex>
  );
};

export default Signature;
