// Layout
import layout from "components/business/layout";
// Pages
import Home from "pages/Home";
import Signature from "pages/Signature";

export enum BackgroundEnum {
  gray = "gray",
  difuminated = "difuminated",
}

const simplePaths = {
  // Generics
  empty: "/",
  home: "/",
  signature: "/signature",
};

type RedirectObject = {
  [key in keyof typeof simplePaths]: () => string;
};

const redirect: RedirectObject = {
  empty: () => simplePaths.empty,
  home: () => simplePaths.home,
  signature: () => simplePaths.signature,
};

const routes: Routes = {
  public: [
    {
      path: simplePaths.home,
      label: "Home",
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: Home,
      exact: true,
    },
    {
      path: simplePaths.signature,
      label: "Signature",
      isShownInTab: true,
      Layout: layout,
      Background: BackgroundEnum.gray,
      Page: Signature,
      exact: true,
    },
  ],
  private: [],
};

// TODO: make it better with connection state
const tabRoutes = [...routes.public, ...routes.private].filter((route) => route.isShownInTab);

export { redirect, routes, tabRoutes };
