import { useMediaQuery } from "@mui/material";
// Styles
import { Breakpoints } from "styles";

interface UseDeviceResponse {
  isMobile: boolean;
  isDesktop: boolean;
  getStyle: <T>(styles: T | [T, T], forceMobile?: boolean) => T;
}

const useDevice = (): UseDeviceResponse => {
  const isMobile = useMediaQuery(`(max-width:${Breakpoints.desktop}px)`);

  /**
   * Return a json style or property. It simplify the media queries
   * @param styles generic styles json or css property
   * @param forceMobile force show the mobile styles in desktop
   * @returns Style Json or Css Property
   */
  const getStyle = <T>(styles: T | [T, T], forceMobile = false) => {
    if (!Array.isArray(styles)) {
      return styles;
    }

    if (forceMobile) {
      return styles[0];
    }

    return isMobile ? styles[0] : styles[1];
  };

  return {
    isMobile,
    isDesktop: !isMobile,
    getStyle,
  };
};

export default useDevice;
