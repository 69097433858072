// Contexts
import "styles/globalStyles.css";

// Apollo
import { ApolloProvider } from "@apollo/client";
// Material
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { routes } from "components/routes/config";
// Components
import Router from "components/routes/Router/Router";
// Config
import { client } from "config";
import { chains, wagmiClient } from "config/connectWallet";
// Hooks
import useUser from "hooks/useUser";
import { BrowserRouter } from "react-router-dom";
// Integration
import { WagmiConfig } from "wagmi";

const flag = false;

const UserRoutes = () => {
  const { isConnected } = useUser();

  const allRoutes = !isConnected && flag ? routes.public : [...routes.public, ...routes.private];

  return <Router routes={allRoutes} />;
};

const App = () => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ApolloProvider client={client}>
            <BrowserRouter>
              <UserRoutes />
            </BrowserRouter>
          </ApolloProvider>
        </LocalizationProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default App;
