import React from "react";
//Types
import { BackgroundEnum } from "styles";

//Styles
import BaseStyles from "./style";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteElementProps<P = any, L = any> = Pick<
  RouteListProps<P, L>,
  "Layout" | "Page" | "Background"
>;

const RouteElement: React.FC<RouteElementProps> = ({ Layout, Page, Background }) => {
  const backgroundStyle =
    Background === BackgroundEnum.difuminated
      ? BaseStyles.backgroundDifuminated
      : BaseStyles.backgroundGray;

  if (Layout) {
    return (
      <Layout>
        <div style={{ ...BaseStyles.root, ...backgroundStyle }}>
          <Page />
        </div>
      </Layout>
    );
  }

  return (
    <div style={BaseStyles.rootWithoutLayout}>
      <Page />
    </div>
  );
};

export default RouteElement;
