import { Skeleton } from "@mui/material";
import React from "react";

// Styles
import { StyledPaper } from "./style";

interface CustomCardProps {
  children?: React.ReactNode;
  style?: Styles;
  size: "medium" | "big";
  Icon?: Icon;
  loading?: boolean;
}

const MAX_BIG_WIDTH = 856;
const MAX_MEDIUM_WIDTH = 410;

const CustomCard: React.FC<CustomCardProps> = ({ style = {}, size, children, loading }) => {
  const maxWidth = size === "big" ? MAX_BIG_WIDTH : MAX_MEDIUM_WIDTH;

  return (
    <StyledPaper style={{ maxWidth, ...style }}>
      {loading ? (
        <Skeleton sx={{ margin: 10 }} variant="rectangular" width={maxWidth - 20} height={400} />
      ) : (
        children
      )}
    </StyledPaper>
  );
};

export default CustomCard;
