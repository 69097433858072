// Material
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// Shared
import { CustomText, Flex } from "components/shared";
// React
import React from "react";
import { useNavigate } from "react-router-dom";
// Styles
import { FontSize, FontWeight } from "styles";

// Internal Styles
import BaseStyles from "./style";

interface SectionHeaderProps {
  onBack?: () => void;
  title: string;
  children?: React.ReactNode;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ onBack, title, children }) => {
  // Hooks
  const navigate = useNavigate();

  // Handlers
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Flex styles={BaseStyles.root}>
      {onBack && (
        <KeyboardBackspaceIcon onClick={onBack || goBack} style={BaseStyles.arrowStyles} />
      )}

      <CustomText
        fontSize={FontSize.xxxxlarge}
        fontWeight={FontWeight.extraBold}
        style={{ color: "rgb(33, 43, 54)" }}
      >
        {title}
      </CustomText>

      {children && <Flex styles={BaseStyles.container}>{children}</Flex>}
    </Flex>
  );
};

export default SectionHeader;
