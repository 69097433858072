const getStyles: StylesFn = (_isMobile: boolean) => ({
  closeContainer: {
    alignItems: "flex-end",
  },
  closeIcon: {
    maxWidth: 40,
  },
});

export default getStyles;
