// Hooks
import useDevice from "hooks/useDevice";
import React from "react";

interface UseStylesProps {
  getStyles: StylesFn;
}

const useStyles = ({ getStyles }: UseStylesProps): Styles => {
  // Hooks
  const { isMobile } = useDevice();

  // Memos
  const BaseStyles = React.useMemo(() => getStyles(isMobile), [getStyles, isMobile]);

  return {
    BaseStyles,
  };
};

export default useStyles;
