// Internal
import RouteElement from "components/routes/RouteElement";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// Utils
import { generateKey } from "utils/react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RouterProps {
  routes: RouteListProps[];
}

const Router: React.FC<RouterProps> = ({ routes }) => {
  return (
    <Routes>
      {routes.map((route) =>
        !route.redirect ? (
          <Route
            key={generateKey("route-")}
            path={route.exact ? route.path : `${route.path}/*`}
            element={
              <RouteElement Layout={route.Layout} Page={route.Page} Background={route.Background} />
            }
          />
        ) : (
          <Route path={route.path} element={<Navigate replace to={route.to!} />} />
        )
      )}
    </Routes>
  );
};

export default Router;
