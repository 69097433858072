import { minHeightTobBar } from "styles/constants";

const BaseStyles = {
  root: {
    width: "100%",
    minHeight: `calc(100vh - ${minHeightTobBar})`,
  },
  linkStyles: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
};

export default BaseStyles;
