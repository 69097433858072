import { styled } from "@mui/material";

// Flex overrides
export const StyledRoot = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  width: "100%",
  height: "100%",
  flexWrap: "wrap",
});

export const TabMenuItemsContainer = {
  display: "flex",
  flex: 1,
  maxWidth: 500,
  justifyContent: "space-around",
  cursor: "pointer",
};
