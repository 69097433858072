// Material
import { AppBar, Container, Toolbar } from "@mui/material";
import { Colors } from "styles";

import Navigation from "./navigation/Navigation";

const TopBar = () => {
  return (
    <AppBar position="sticky" elevation={0} sx={{ background: Colors.greyDarker }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Navigation />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default TopBar;
