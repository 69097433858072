// Styles
import "./Navigation.css";

// Utils
import { redirect } from "components/routes/config";
// Components
import { Button, Flex } from "components/shared";
// Images
import WakeUpLogo from "Images/logo-tokengated-dapp.png";
import { useNavigate } from "react-router-dom";

import { StyledRoot } from "./style";

export default function Navigation() {
  // Hooks
  const navigate = useNavigate();

  return (
    <StyledRoot>
      <Flex row styles={{ width: "100%", justifyContent: "space-around" }}>
        <Flex>
          <img
            src={WakeUpLogo}
            alt="wakeupLogo"
            onClick={() => navigate(redirect.home())}
            style={{ cursor: "pointer", width: 100 }}
          />
        </Flex>
        <Button connect color="secondary" />
      </Flex>
    </StyledRoot>
  );
}
