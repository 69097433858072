export const envKeys = {
  //Crypto
  alchemy: {
    id: process.env.REACT_APP_ALCHEMY_ID,
  },
  contract: {
    address: process.env.REACT_APP_CONTRACT_ADDRESS,
    wakeUp: process.env.REACT_APP_WAKEUP,
  },
  // Apollo
  apolloClient: {
    uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
  },
  testnet: {
    uri: process.env.REACT_APP_TEST_NET_URI ?? "https://testnet.alpha.app.wakeuplabs.io/rent",
  },
  // WAKE UP SERVICES AND WEBS
  api: {
    minterUrl: process.env.REACT_APP_API_MINTER_URL,
    key: process.env.REACT_APP_API_KEY,
  },
  wakeup: {
    rentalUrl: process.env.REACT_APP_API_RENTAL_URL,
  },
};
