import { v4 as uuidv4 } from "uuid";

/**
 * generate a random and unique key for react element used in lists
 * @param prefix optional prefix for the key
 */
const generateKey = (prefix?: string): string => {
  return prefix ? `${prefix}-${uuidv4()}` : uuidv4();
};

export { generateKey };
