import { gql } from "@apollo/client";

// Queries
export const rentInformationsOwner = gql`
  query ($owner: String!) {
    rentInformations(where: { owner_contains: $owner }) {
      id
      user
      expires
      owner
      collection
    }
  }
`;

export const rentInformationsUser = gql`
  query ($user: String!) {
    rentInformations(where: { user_contains: $user }) {
      id
      user
      expires
      owner
      collection
    }
  }
`;
