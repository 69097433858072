// Bussiness
import { CardWithImage } from "components/business";
import AsyncNftCard from "components/business/AsyncNftCard";
// Shared
import { Button, CustomText, Flex, Modal } from "components/shared";
// Hooks
import useModal from "hooks/useModal";
import useStyles from "hooks/useStyles";
// Images
import { CongratsIcon } from "Images";
import React from "react";
// Styles
import { FontWeight } from "styles";
// Utils
import { getText } from "utils/plurals";

import getStyles from "./styles";

interface SuccessProps {
  ownNftsCount: number;
  delegateNftsCount: number;
  nft: NFTResponse;
}

const line1 = "Congrats, fren!";
const line2 = "You have the required NFT rights so you	can	now	access the full	experience 🚀";

const Success: React.FC<SuccessProps> = ({ ownNftsCount, delegateNftsCount, nft }) => {
  // States
  const [showNftDetails, setShowNftDetails] = React.useState(false);

  // Hooks
  const { open, toggle } = useModal();
  const { BaseStyles } = useStyles({ getStyles });

  // Handlers
  const getTypeOfNftAccess = () => {
    if (delegateNftsCount > 0) {
      return "Delegate";
    }

    if (ownNftsCount > 0) {
      return "Owner";
    }
  };

  const handleToggleView = () => setShowNftDetails((prev) => !prev);

  const [collectionAddress, nftId] = nft.id.split("/"); 

  return (
    <CardWithImage
      Icon={CongratsIcon}
      title="NFT Access Granted! ✅"
      FooterComponent={() => (
        <>
          <CustomText>
            {line1}
            <br />
            {line2}
          </CustomText>

          <CustomText style={BaseStyles.typeAccess} fontWeight={FontWeight.bold}>
            Type of NFT Access: {getTypeOfNftAccess()}
          </CustomText>

          <Flex styles={BaseStyles.buttonsContainer}>
            <Button
              style={BaseStyles.button}
              text="View NFT"
              onClick={toggle}
              size="small"
              variant="text"
            />

            <Button
              style={BaseStyles.button}
              text={showNftDetails ? "Less Details" : "More Details"}
              onClick={handleToggleView}
              size="small"
              variant="text"
            />
          </Flex>

          {/* Hide NFT Details */}
          <Flex styles={{ ...BaseStyles.nftDetails, opacity: showNftDetails ? 1 : 0 }}>
            <CustomText>
              <b>Own:</b> {`${ownNftsCount} ${getText(ownNftsCount, "NFT")}`}
            </CustomText>
            <CustomText>
              <b>Received:</b> {`${delegateNftsCount} ${getText(delegateNftsCount, "NFT")}`}
            </CustomText>
          </Flex>

          <Modal open={open} onClose={toggle}>
            <AsyncNftCard
              tokenId={nftId}
              tokenAddress={collectionAddress}
            />
          </Modal>
        </>
      )}
    />
  );
};

export default Success;
