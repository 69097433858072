// Material UI
import { Box } from "@mui/material";
// Components
import { CardWithImage as EmptyStateCard, SectionHeader } from "components/business";
import { CustomText, Flex } from "components/shared";
// Images
import { EmptyStateWallet } from "Images";
import React from "react";
// Crypto
import { useAccount } from "wagmi";

// Internal
import CheckOwnNfts from "./CheckOwnNfts";
// Styles
import BaseStyles from "./style";

interface FormProps {}

// eslint-disable-next-line max-len
const line1 =
  "Please connect your blockchain wallet so we can check if you are eligible to access our online courses.";
const line2 = "You can be an owner, a delegate, or a renter of the required NFT.";

const Form: React.FC<FormProps> = () => {
  const { data: rainbowData } = useAccount();

  const address = rainbowData?.address;
  if (!address) {
    return (
      <Box paddingTop="32px">
        <EmptyStateCard
          Icon={EmptyStateWallet}
          title="Welcome to the Token Gated Dapp!"
          FooterComponent={() => (
            <CustomText>
              {line1}
              <br />
              {line2}
            </CustomText>
          )}
          callToActionProps={{ connect: true }}
        />
      </Box>
    );
  }

  return (
    <Flex styles={BaseStyles.root}>
      <Flex>
        <SectionHeader title="Check NFTs">
          <CheckOwnNfts ownerAddress={address} />
        </SectionHeader>
      </Flex>
    </Flex>
  );
};

export default Form;
