import { Colors } from "styles";

const BaseStyles = {
  root: {
    alignItems: "flex-start",
    width: "100%",
    gap: 24,
  },
  container: {
    gap: 24,
    flexWrap: "wrap",
    alignItems: "flex-start",
    flex: 1,
  },
  arrowStyles: {
    cursor: "pointer",
    color: Colors.greyDark,
    width: 32,
    height: 32,
  },
};

export default BaseStyles;
