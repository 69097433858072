import React from "react";
// Utils
import { sendRequest, SendRequestProps } from "utils/apiUtils";

interface Options extends Omit<SendRequestProps, "url"> {
  enabled?: boolean;
  params?: CommonJSON;
  data?: CommonJSON;
}

interface UseRestResponse<T> {
  data: T;
  error: RestError | null;
  loading: boolean;
}

const useRest = <DataRestResponse = unknown>(
  url: string | undefined,
  options: Options
): UseRestResponse<DataRestResponse> => {
  // Refs
  const loading = React.useRef(false);

  // States
  const [data, setData] = React.useState<CommonJSON | null | undefined>(null);
  const [error, setError] = React.useState<RestError | null>(null);

  const { enabled = true } = options;

  // Effects
  React.useEffect(() => {
    const callRequest = async (url: string) => {
      try {
        loading.current = true;
        const res = await sendRequest({ url, ...options });
        setData(res);
        loading.current = false;
      } catch (e: unknown) {
        const err = e as RestError;
        loading.current = false;
        setError(err);
      }
    };

    if (enabled && url) {
      try {
        void callRequest(url);
      } catch (e) {
        console.error(e);
      }
    }
  }, [enabled]);

  return {
    data: data as DataRestResponse,
    error,
    loading: loading.current,
  };
};

export default useRest;
