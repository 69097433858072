// Material
import { CircularProgress } from "@mui/material";
// Shared
import { Flex } from "components/shared";
import React from "react";

interface LoadingProps {}

const Loading: React.FC<LoadingProps> = () => {
  return (
    <Flex>
      <CircularProgress />
    </Flex>
  );
};

export default Loading;
