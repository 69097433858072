import { LaunchOutlined } from "@mui/icons-material";
import { Link } from "@mui/material";
// Bussiness
import { CardWithImage } from "components/business";
// Shared
import { CustomText, Flex } from "components/shared";
// Config
import { envKeys } from "config";
// Hooks
import useStyles from "hooks/useStyles";
// Images
import { NftStarsIcon } from "Images";
// React
import React from "react";

import getStyles from "./styles";

interface FailureProps {
  onClick: () => void;
}

const Failure: React.FC<FailureProps> = ({ onClick }) => {
  const { BaseStyles } = useStyles({ getStyles });

  return (
    <CardWithImage
      Icon={NftStarsIcon}
      title="Sorry, ser! You don’t have the required NFT access ❌"
      FooterComponent={() => (
        <CustomText>
          {/* eslint-disable-next-line max-len */}
          We like you, but at this moment you are not an owner nor a delegate or renter of the
          required NFT collection. <br />
          <Flex>
            <div>
              Please go to&nbsp;
              <Link target="_blank" href={envKeys.wakeup.rentalUrl}>
                this dapp
                <LaunchOutlined style={BaseStyles.linkIcon} />
              </Link>
              &nbsp;to rent an NFT and come back soon!
            </div>
          </Flex>
        </CustomText>
      )}
      callToActionProps={{
        text: "Check Again",
        onClick,
      }}
    />
  );
};

export default Failure;
