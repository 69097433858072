// Material
import { Box } from "@mui/system";
//Shared
import { CustomText, Flex, Image, Loading } from "components/shared";
//Hooks
import useDevice from "hooks/useDevice";
// React
import React from "react";
//Images
import { Colors } from "styles";
//Styles
import { FontSize, FontWeight } from "styles/font";

import {
  boxButtonStyles,
  boxMobileButtonStyles,
  descriptionStyles,
  flexStyles,
  flexStylesMobile,
  StyledBox,
} from "./styles";

export interface NftCardProps {
  loading?: boolean;
  title: string;
  subtitle: string;
  image?: string;
  label?: string;
  labelTextColor?: Colors;
  backgroundLabel?: Colors;
  variant?: "horizontal" | "vertical";
  onClickIcon?: () => void;
  IconLabel?: Icon;
}

const NftCard: React.FC<NftCardProps> = ({
  loading,
  title,
  subtitle,
  label,
  image,
  labelTextColor,
  backgroundLabel = Colors.blackLight,
  variant = "vertical",
  IconLabel,
}) => {
  // States
  const [loadedImg, setLoadedImg] = React.useState(false);

  // Hooks
  const { isMobile } = useDevice();
  const imageStyle = loadedImg ? { maxWidth: "100%" } : { display: "none" };

  // Variables
  const isHorizontal = isMobile && variant === "horizontal";

  if (loading) {
    return (
      <Flex styles={isHorizontal ? flexStylesMobile : flexStyles}>
        <Loading />
      </Flex>
    );
  }

  return (
    <Flex styles={isHorizontal ? flexStylesMobile : flexStyles}>
      <Box>
        {label && (
          <StyledBox style={{ color: labelTextColor, background: backgroundLabel }}>
            {IconLabel && <IconLabel />}
            <CustomText fontSize={FontSize.xxsmall} fontWeight={FontWeight.medium}>
              {label}
            </CustomText>
          </StyledBox>
        )}

        {image && (
          <>
            {!loadedImg && <Loading />}
            <Image
              url={image}
              alt="nftImage"
              style={imageStyle}
              onLoad={() => setLoadedImg(true)}
            />
          </>
        )}
      </Box>

      <Box sx={isHorizontal ? boxMobileButtonStyles : boxButtonStyles}>
        {/* Title */}
        <CustomText fontSize={FontSize.large} fontWeight={FontWeight.extraBold}>
          {title}
        </CustomText>

        {/* Subtitle */}
        <CustomText style={descriptionStyles}>{subtitle}</CustomText>
      </Box>
    </Flex>
  );
};

export default NftCard;
