/**
 * Returns the correct label depending on the value of count
 * IMPROVEMENT: use a library as i18next
 * @param count count of items
 * @param singular label in singular
 * @param plural label in plural. Singular + s as default
 * @returns singular when count === 1.
 */
export const getText = (count: number, singular: string, plural?: string): string => {
  if (count === 1) {
    return singular;
  }

  return !plural ? `${singular}s` : plural;
};
