// Material
import { CloseOutlined } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";
// Shared
import { Flex } from "components/shared";
// Hooks
import useStyles from "hooks/useStyles";
// React
import React from "react";

import getStyles from "./style";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ open, onClose, children }) => {
  const { BaseStyles } = useStyles({ getStyles });

  return (
    <Dialog open={open} onClose={onClose}>
      <Flex styles={BaseStyles.closeContainer}>
        <IconButton style={BaseStyles.closeIcon} onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </Flex>

      {children}
    </Dialog>
  );
};

export default Modal;
