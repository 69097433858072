import { useQuery } from "@apollo/client";
// Api
import { rentInformationsOwner, rentInformationsUser } from "api/graphql/subgraphQueries";
// Bussiness
import { CardWithImage } from "components/business";
// Shared
import { CustomText, Loading } from "components/shared";
// Images
import { NftStarsIcon } from "Images";
import React from "react";
// Utils
import { getExpiresNftParsed } from "utils/date";

import { Failure } from "./Steps";
// Steps
import Success from "./Steps/Success";

interface CheckOwnNftsProps {
  ownerAddress: string;
}

const environment = process.env;
const LOADING_TIME = 2.5 * 1000; // 2.5 SECONDS

// Messages constants
const line1 = "We need to check if you are eligible to access our online courses.";
const line2 = `You can be an owner, a delegate or a renter of the required NFT collection. ${environment.REACT_APP_ENV_TAG}`;

const loadingMessage = "Please, wait a second while we check your NFTs";

const CheckOwnNfts: React.FC<CheckOwnNftsProps> = ({ ownerAddress }) => {
  // States
  const [checked, setChecked] = React.useState(false);
  const [loadingSimulated, setLoadingSimulated] = React.useState(false);

  // Queries
  // Own NFTs
  const {
    loading: loadingRentinfoOwner,
    data: dataRentInfo,
    refetch: refetchRentInfo,
  } = useQuery<GetRentInformationOwnerResponse>(rentInformationsOwner, {
    notifyOnNetworkStatusChange: true,
    variables: {
      owner: ownerAddress,
    },
    skip: !checked,
    fetchPolicy: "network-only",
  });

  // Delegated NFTs
  const {
    loading: loadingUserinfoOwner,
    data: dataRentUser,
    refetch: refetchRentUser,
  } = useQuery<GetRentInformationUserResponse>(rentInformationsUser, {
    variables: {
      user: ownerAddress,
    },
    skip: !ownerAddress,
    fetchPolicy: "network-only",
  });

  const handleCheck = () => {
    setChecked(true);
  };

  const handleRefetchQueries = () => {
    setLoadingSimulated(true);
    refetchRentInfo({
      owner: ownerAddress,
    });
    refetchRentUser({
      user: ownerAddress,
    });

    setTimeout(() => {
      setLoadingSimulated(false);
    }, LOADING_TIME);
  };

  // Memos
  const ownNfts = React.useMemo(
    () => dataRentInfo?.rentInformations || [],
    [dataRentInfo?.rentInformations]
  );

  const delegateNfts = React.useMemo(
    () =>
      (dataRentUser?.rentInformations || []).filter((nft) => {
        const nowDateParsed = getExpiresNftParsed(nft.expires);

        return parseInt(nft.expires, 10) > Math.floor(nowDateParsed);
      }),
    [dataRentUser?.rentInformations]
  );

  const data = React.useMemo(() => {
    return [...delegateNfts, ...ownNfts];
  }, [delegateNfts, ownNfts]);

  // Variables
  const loading = loadingRentinfoOwner || loadingUserinfoOwner || loadingSimulated;
  const isOWner = data.length > 0;
  const hasChecked = checked && data && !loading;

  if (loading) {
    return (
      <CardWithImage Icon={() => <Loading />} title="Checking" description={[loadingMessage]} />
    );
  }

  if (hasChecked && isOWner) {
    const nft = data[0];

    return (
      <Success ownNftsCount={ownNfts.length} delegateNftsCount={delegateNfts.length} nft={nft} />
    );
  }

  if (hasChecked && !isOWner) {
    return <Failure onClick={handleRefetchQueries} />;
  }

  return (
    <CardWithImage
      Icon={NftStarsIcon}
      title="Hey fren! Please check your NFT access eligibility!"
      FooterComponent={() => (
        <CustomText>
          {line1}
          <br />
          {line2}
        </CustomText>
      )}
      callToActionProps={{
        text: "Check",
        onClick: handleCheck,
      }}
    />
  );
};

export default CheckOwnNfts;
