const getStyles: StylesFn = (_isMobile: boolean) => ({
  typeAccess: {
    padding: "24px 0px",
  },
  // Buttons section
  buttonsContainer: {
    alignItems: "flex-start",
    gap: 4,
  },
  button: {
    justifyContent: "flex-start",
  },
  nftDetails: {
    alignItems: "flex-start",
    paddingLeft: 24,
  },
});

export default getStyles;
