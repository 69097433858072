import React from "react";
// Wagmi
import { useConnect } from "wagmi";

interface UseUserProps {
  isConnected: boolean;
  onFakeConnect: () => void;
}

const useUser = (): UseUserProps => {
  const [fakeConnect, setFakeConnect] = React.useState<boolean>(false);

  const { isConnected } = useConnect();

  const onFakeConnect = () => {
    setFakeConnect((prev) => !prev);
  };

  return {
    isConnected: isConnected || fakeConnect,
    onFakeConnect,
  };
};

export default useUser;
