// Material
import { createTheme } from "@mui/material";

// Styles
import { Colors } from "./colors";

export enum BackgroundEnum {
  gray = "gray",
  difuminated = "difuminated",
}

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.orange,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.greenBright,
      contrastText: Colors.violetMain,
    },
    background: {
      default: Colors.extraLightgray,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          height: "40px",
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
});

export default theme;
