// Shared Components
import { CustomCard, CustomText, Flex, Image } from "components/shared";
// Types
import Button, { CustomButtonProps } from "components/shared/Button";
// Hooks
import useDevice from "hooks/useDevice";
import React from "react";
// Styles
import { FontSize, FontWeight } from "styles";
// Utils
import { generateKey } from "utils/react";

import getStyles from "./style";

interface CardWithImageProps {
  Icon?: Icon;
  imageUrl?: string;
  title?: string;
  description?: string[];
  callToActionProps?: CustomButtonProps;
  CallToAction?: React.FC<CustomButtonProps>;
  variant?: "vertical" | "horizontal";
  FooterComponent?: React.FC;
}

const CardWithImage: React.FC<CardWithImageProps> = ({
  Icon,
  imageUrl,
  title,
  description = [],
  callToActionProps,
  CallToAction,
  variant = "horizontal",
  FooterComponent,
}) => {
  const { isMobile, getStyle } = useDevice();

  const isVertical = isMobile || variant === "vertical";

  const BaseStyles = getStyles(isVertical);

  return (
    <Flex>
      <Flex>
        <Flex styles={BaseStyles.root}>
          <CustomCard style={BaseStyles.cardContainer} size={isVertical ? "medium" : "big"}>
            {(Icon || imageUrl) && (
              <div style={BaseStyles.section}>
                <Image Icon={Icon} url={imageUrl} />
              </div>
            )}

            <div style={{ ...BaseStyles.section, ...BaseStyles.sectionDescription }}>
              {title && (
                <CustomText
                  fontSize={getStyle<FontSize>([FontSize.large, FontSize.xxxlarge], isVertical)}
                  fontWeight={getStyle<FontWeight>(
                    [FontWeight.medium, FontWeight.bold],
                    isVertical
                  )}
                  style={BaseStyles.title}
                >
                  {title}
                </CustomText>
              )}

              {description.map((line) => (
                <CustomText key={generateKey()} style={BaseStyles.description}>
                  {line}
                </CustomText>
              ))}
              <div style={BaseStyles.description}>{FooterComponent && <FooterComponent />}</div>
            </div>
          </CustomCard>

          {callToActionProps && (
            <Button
              style={{ ...BaseStyles.button, ...callToActionProps.style }}
              size="large"
              {...callToActionProps}
            />
          )}
          {CallToAction && <CallToAction />}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CardWithImage;
