// Hooks
import useRest from "api/hooks/useRest";
// Components
import NftCard from "components/business/NftCard";
// Config
import { envKeys } from "config";
// React
import React from "react";

interface AsyncNftCardProps {
  tokenId: string;
  tokenAddress: string;
}

interface TokenMetadata {
  name: string;
  description: string;
  image: string;
}

interface TokenResponse {
  metadata: TokenMetadata;
  owner: string;
}

const AsyncNftCard: React.FC<AsyncNftCardProps> = ({ tokenAddress, tokenId }) => {
  const { loading: loading, data } = useRest<TokenResponse>(`${envKeys.api.minterUrl}/token/any`, {
    params: {
      blockchain: "Polygon_Mumbai",
      nftType: "ERC721",
      tokenAddress,
      tokenId,
    },
    headers: {
      apiKey: envKeys.api.key,
      "Content-Type": "application/json",
    },
  });

  const nft = data?.metadata;

  return (
    <NftCard
      title={nft ? `${nft?.name} ${tokenId}` : ""}
      subtitle={nft?.description || ""}
      image={nft?.image}
      loading={loading || !tokenId || !tokenAddress || !nft}
    />
  );
};

export default AsyncNftCard;
