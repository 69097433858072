import React from "react";

interface UseModalResponse {
  open: boolean;
  toggle: () => void;
}

const useModal = (): UseModalResponse => {
  const [open, setOpen] = React.useState(false);

  const toggle = () => setOpen((prev) => !prev);

  return {
    open,
    toggle,
  };
};

export default useModal;
